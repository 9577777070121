import { setStorageData, removeStorageData } from "../../framework/src/Utilities";

export class GoogleAuthProvider {

  // Get Google Access Token
  static getAccessToken = async (): Promise<string> => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    try {
      const response = await auth2.signIn();
      const user = response.getAuthResponse(true);
      setStorageData("google_auth", user.access_token);
      return user.access_token;
    } catch (error) {
      console.error("Google authentication failed:", error);
      throw error;
    }
  };

  // Sign Out from Google
  static signOut = async () => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    try {
      if (auth2.isSignedIn.get()) {
        const currentUser = auth2.currentUser.get();
        currentUser.disconnect();
        await auth2.signOut();
      }
      removeStorageData("google_auth");
      return true;
    } catch (error) {
      console.error("Error signing out from Google:", error);
      return false;
    }
  };

  // Refresh Google Access Token (if required)
  static refreshAccessToken = async (): Promise<string> => {
    const auth2 = window.gapi.auth2.getAuthInstance();
    try {
      const user = auth2.currentUser.get();
      if (user.isSignedIn()) {
        const refreshedAuthResponse = await user.reloadAuthResponse();
        setStorageData("google_auth", refreshedAuthResponse.access_token);
        return refreshedAuthResponse.access_token;
      } else {
        return await GoogleAuthProvider.getAccessToken();
      }
    } catch (error) {
      console.error("Error refreshing Google token:", error);
      throw error;
    }
  };

}
