// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import { failureImage, successImage } from "./assets";

export const configJSON = require("./config");

interface ValidResponseType {
  message: object;
  data: object;
  errors: string;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  loader: boolean;
  isSuccessFailurePopupShown: boolean;
  popupImg: string;
  popupText: string;
  popupSubText: string;
  buttonText: string;
}

interface SS {
  id: any;
}

export default class DocusignIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  documentSignedApiCallId: string = "";

  constructor(Props: Props) {
    super(Props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loader: true,
      isSuccessFailurePopupShown: false,
      popupImg: "",
      popupText: "",
      popupSubText: "",
      buttonText: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  isValidResponse = (responseJson: ValidResponseType) =>
    responseJson && !responseJson.errors;

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(webResponseJson)) {
        this.setState({
          popupImg: successImage,
          popupText: "Document Signed Successfully!",
          popupSubText: "Document has been sent to your email successfully",
          buttonText: "Check Status",
          isSuccessFailurePopupShown: true,
          loader: false,
        });
      } else {
        this.setState({
          popupImg: failureImage,
          popupText: "Document Signing Failed!",
          popupSubText: "Please try again",
          buttonText: "OK",
          isSuccessFailurePopupShown: true,
          loader: false,
        });
      }
    }
  }

  async componentDidMount() {
    this.documentSignedApiCall();
  }

  documentSignedApiCall = async () => {
    let token = await getStorageData("token");
    let documentSignedId = await getStorageData("signedDocumentId");
    removeStorageData("signedDocumentId");
    const header = {
      "Content-Type": configJSON.documentSignedApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.documentSignedApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.documentSignedApiEndPoint + documentSignedId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.documentSignedApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToRequestDetails = () => {
    const { parent } = window;
    const { location } = parent;
    location.href = location.origin + "/RequestDetails";
  };
}
// Customizable Area End
