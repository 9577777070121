// Customizable Area Start
import React from "react";
import {
  AppBar,
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import {
  localProfile,
  bellIcon,
  service,
  cancelImage,
  verified,
  emptyFolder,
  logoBlueImg,
} from "./assets";
import {
  ArrowBackIosRounded,
  AddRounded,
  DescriptionOutlined,
  Menu,
  Close,
  EventOutlined,
  VisibilityOutlined,
} from "@material-ui/icons";
import {
  MdOutlineFileDownload,
  MdOutlinePayments,
  MdOutlineNotes,
  MdOutlineLocationOn,
} from "react-icons/md";
import CancelNotaryRequestModal from "../../../components/src/CancelNotaryRequestModal.web";
import RequestDetailsController, {
  FileDocumentsEntity,
  Props,
} from "./RequestDetailsController";
import Loader from "../../../components/src/Loader.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import CustomAutocomplete from "../../../components/src/CustomAutocomplete.web";
import CustomTextField from "../../../components/src/CustomTextField.web";
import { RiTimerLine } from "react-icons/ri";
import { IoCheckbox } from "react-icons/io5";
import { RxBox } from "react-icons/rx";
import { BiMessageSquareDetail } from "react-icons/bi";
import { FiArrowUpRight } from "react-icons/fi";
import RequestModal from "./../../dashboard/src/BookNotaryRequest.web";
import SuccessFailureModal from "../../../components/src/SuccessFailureModal.web";
import KnowYourCustomerKycVerification from "../../knowyourcustomerkycverification/src/KnowYourCustomerKycVerification.web"
import "date-fns";
import DateFnsUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import TrackingDetails from "../../ordermanagement/src/TrackingDetails.web";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
// Customizable Area End

export default class RequestDetailsWeb extends RequestDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Header = () => {
    return (
      <Box display={"flex"} alignItems={"center"} mt={"32px"}>
        <StyledIconButton data-testID="toggleButton" onClick={this.openSideBar}>
          {this.state.isSideBarOpen ? (
            <Close style={{ width: "50px", height: "56px" }} />
          ) : (
            <Menu style={{ width: "50px", height: "56px" }} />
          )}
        </StyledIconButton>
        <Box width={"calc(100vw - 74px)"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mx={"25px"}
          >
            <Box
              display={"flex"}
              height={"48px"}
              alignItems={"center"}
              style={{ columnGap: "8px" }}
            >
              <IconButton
                data-testID="backIconButton"
                onClick={this.navigateBack}
              >
                <ArrowBackIosRounded height={"24px"} width={"24px"} />
              </IconButton>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "32px",
                  letterSpacing: "-0.5%",
                }}
              >
                Order ID:{this.state.notaryRequestId}
              </Typography>
              <StatusBox>
                <Typography
                  className={`${this.state.status
                    .toLowerCase()
                    .replace(/\s/g, "")} statusBox`}
                  style={{
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 700,
                    lineHeight: "18px",
                  }}
                  variant="body1"
                >
                  {this.state.status.toUpperCase()}
                </Typography>
              </StatusBox>
            </Box>
            <Box display={"flex"} style={{ columnGap: "16px" }}>
              {this.isNotaryUser() && (
                <Button
                  style={{
                    backgroundColor: "#012275",
                    color: "#FFF",
                    height: "48px",
                    padding: "0 16px",
                  }}
                  startIcon={<AddRounded />}
                  data-testID="bookNowBtn"
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Invite Client
                  </Typography>
                </Button>
              )}
              {this.isEndUser() && (
                <Button
                  style={{
                    backgroundColor: "#012275",
                    color: "#FFF",
                    height: "48px",
                    padding: "0 16px",
                    textTransform: "none",
                  }}
                  onClick={() => this.setBookNowModal(true)}
                  startIcon={
                    <AddRounded style={{ height: "24px", width: "24px" }} />
                  }
                  data-testID="bookNowBtn"
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 700,
                    }}
                  >
                    Book Now
                  </Typography>
                </Button>
              )}
              <Box
                borderRadius={"4px"}
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#FFF",
                }}
                width={"48px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"48px"}
              >
                <img src={bellIcon} />
              </Box>
              <Box
                borderRadius={"8px"}
                display={"flex"}
                height={"48px"}
                px={"8px"}
                alignItems={"center"}
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  columnGap: "8px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <img
                  style={{ borderRadius: "50%", backgroundColor: "grey" }}
                  src={
                    this.state.userProfilePic !== null
                      ? this.state.userProfilePic
                      : localProfile
                  }
                  width="32px"
                  height="32px"
                  alt="profile_pic"
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    fontFamily: "Inter",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                  data-testID="userName"
                  variant="body2"
                >
                  {this.state.userName}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  renderMobileNavigationMenu = () => {
    return (
      <>
        {this.state.isSideBarOpen && (
          <NavigationMenu navigation={this.props.navigation} id={""} />
        )}
      </>
    );
  };

  renderCancelRequestButton = () => {
    return (
      <>
        {this.isCancelButtonShown() && (
          <Button
            data-testID="cancelButton"
            variant="contained"
            style={{
              backgroundColor: "#DC2626",
              borderRadius: "4px",
              color: "#FFF",
              padding: "0 12px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={this.openModalForCancellation}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "18px",
              }}
            >
              CANCEL REQUEST
            </Typography>
          </Button>
        )}
      </>
    );
  };

  renderMakeQuoteAndDeclineButton = () => {
    return (
      <>
        {this.areMakeQuoteAndDeclineButtonsShown() && (
          <Box
            mt={"8px"}
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box
              mx={"24px"}
              display={"flex"}
              style={{ columnGap: "12px" }}
              width={"100%"}
            >
              <Button
                variant="outlined"
                fullWidth
                style={{
                  ...webStyle.buttonStyle,
                  borderColor: "#FF0000",
                  color: "#FF0000",
                  textTransform: "none",
                }}
              >
                <Typography style={webStyle.buttonTypography}>
                  Decline
                </Typography>
              </Button>
              <Button
                variant="contained"
                fullWidth
                style={{
                  ...webStyle.buttonStyle,
                  backgroundColor: "#012275",
                  color: "#FFF",
                  textTransform: "none",
                }}
                data-testID="openMakeQuoteModal"
                onClick={this.toggleIsMakeQuoteModalOpen}
              >
                <Typography style={webStyle.buttonTypography}>
                  Make Quote
                </Typography>
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  };

  renderCancelQuoteButton = (quoteStatusId: number, quoteId: string) => {
    return (
      <>
        {this.isQuoteSubmitted(quoteStatusId) && (
          <Button
            data-testID="cancelQuoteButton"
            variant="contained"
            style={{
              backgroundColor: "#DC2626",
              borderRadius: "4px",
              color: "#FFF",
              padding: "12px",
              width: "156px",
              height: "44px",
              textTransform: "none",
            }}
            onClick={() =>
              this.openModal(
                "Are you sure you want to withdraw this quote?",
                quoteId
              )
            }
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 700,
              }}
            >
              Cancel
            </Typography>
          </Button>
        )}
      </>
    );
  };

  renderNullQuotesMessage = () => {
    return (
      <>
        {this.isQuotesNullForEndUser() && (
          <Box data-testID={"noQuotesMessage"} p={"24px"}>
            <Typography style={webStyle.tabTitle}>Quotes List</Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              style={{ rowGap: "14px" }}
              alignItems={"center"}
            >
              <Box p={"16px"}>
                <img src={emptyFolder} />
              </Box>
              <Typography style={webStyle.tabTitle}>No Quotes yet</Typography>
              <Box maxWidth={"329px"}>
                <Typography style={webStyle.kycInfoText} align="center">
                  stay calm you'll get one very soon
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </>
    );
  };

  renderQuotesForEndUser = () => {
    return (
      <>
        {this.areQuotesAvailableForEndUser() &&
          this.state.quotes.map((quote, quoteIndex: number) => (
            <div key={quoteIndex}>
              <Box
                p={"24px"}
                display={"flex"}
                flexDirection={"column"}
                style={{
                  backgroundColor: "#FFF",
                  borderRadius: "8px",
                  gap: "16px",
                }}
              >
                <Typography style={webStyle.quoteTitle}>
                  Quote #{quoteIndex + 1}
                </Typography>
                <Grid container>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Box
                      display={"flex"}
                      width={"90%"}
                      flexDirection={"column"}
                      style={{ gap: "4px" }}
                    >
                      <Typography style={webStyle.quoteDetailTypography1}>
                        Notary Service:
                      </Typography>
                      <Typography style={webStyle.quoteDetailTypography2}>
                        {this.state.typeOfNotaryService}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Box
                      display={"flex"}
                      width={"90%"}
                      flexDirection={"column"}
                      style={{ gap: "4px" }}
                    >
                      <Typography style={webStyle.quoteDetailTypography1}>
                        Notary Date & Time:
                      </Typography>
                      <Typography style={webStyle.quoteDetailTypography2}>
                        {this.getQuoteDateTime(quote.attributes.start_time)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      style={{ gap: "4px" }}
                    >
                      <Typography style={webStyle.quoteDetailTypography1}>
                        Fees:
                      </Typography>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Typography style={webStyle.quoteDetailTypography1}>
                          Notary Fees:
                        </Typography>
                        <Typography style={webStyle.quoteDetailTypography1}>
                          £{parseFloat(quote.attributes.notary_fees).toFixed(2)}
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Typography style={webStyle.quoteDetailTypography1}>
                          Platform Fees:
                        </Typography>
                        <Typography style={webStyle.quoteDetailTypography1}>
                          +£
                          {parseFloat(quote.attributes.platform_fees).toFixed(
                            2
                          )}
                        </Typography>
                      </Box>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <Typography style={webStyle.quoteDetailTypography2}>
                          Total:
                        </Typography>
                        <Typography style={webStyle.quoteDetailTypography2}>
                          £
                          {(
                            parseFloat(quote.attributes.notary_fees) +
                            parseFloat(quote.attributes.platform_fees)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box width={"100%"} display={"flex"} justifyContent={"end"}>
                  <Button
                    variant="contained"
                    style={{
                      ...webStyle.tabInsiderButton,
                      textTransform: "none",
                      width: "156px",
                    }}
                    data-testID="previewQuoteButton"
                    onClick={() => this.previewQuote(quote, quoteIndex + 1)}
                  >
                    <Typography style={webStyle.tabInsiderButtonTypography}>
                      Preview
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </div>
          ))}
      </>
    );
  };

  renderQuotesForNotaryUser = () => {
    return (
      <>
        {this.areQuotesAvailableForNotaryUser() &&
          this.state.quotes.map((quote, quoteIndex: number) => (
            <div key={quoteIndex}>
              <Box
                p={"24px"}
                display={"flex"}
                flexDirection={"column"}
                style={{
                  backgroundColor: "#FFF",
                  borderRadius: "8px",
                  gap: "16px",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  style={{ rowGap: "16px" }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ rowGap: "4px" }}
                  >
                    <Typography style={webStyle.quoteDetailTypography1}>
                      Quote Date:
                    </Typography>
                    <Typography style={webStyle.quoteDetailTypography2}>
                      {this.formNotaryRequestDate()}
                    </Typography>
                  </Box>
                  {this.renderCancelQuoteButton(
                    quote.attributes.quote_statuses_id,
                    quote.id
                  )}
                  {!this.isQuoteSubmitted(
                    quote.attributes.quote_statuses_id
                  ) && (
                    <Box width={"123px"}>
                      <StatusBox width={"90px"}>
                        <Typography
                          className={`cancelled statusBox`}
                          style={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 700,
                            lineHeight: "18px",
                          }}
                          variant="body1"
                        >
                          Withdrawn
                        </Typography>
                      </StatusBox>
                    </Box>
                  )}
                </Box>
                <Divider style={{ color: "#CBD5E1" }} />
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ rowGap: "4px" }}
                  >
                    <Typography style={webStyle.quoteDetailTypography1}>
                      Start time & end time
                    </Typography>
                    <Typography style={webStyle.quoteDetailTypography2}>
                      {this.getQuoteMeetTime(
                        quote.attributes.start_time,
                        quote.attributes.end_time
                      )}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    width={"160px"}
                    flexDirection={"column"}
                    style={{ rowGap: "4px" }}
                  >
                    <Typography style={webStyle.quoteDetailTypography1}>
                      Fees:
                    </Typography>
                    <Typography style={webStyle.quoteDetailTypography2}>
                      £{parseFloat(quote.attributes.notary_fees).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </div>
          ))}
      </>
    );
  };

  renderMeetingTab = () => {
    return (
      <>
        {this.toShowMeetingTab() &&  (
          <Box
            p={"24px"}
            display={"flex"}
            flexDirection={"column"}
            style={{
              borderRadius: "8px",
              gap: "16px",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              style={{ gap: "24px" }}
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  style={{ gap: "8px" }}
                >
                  <MdOutlineNotes
                    style={{
                      color: "#475569",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  <Typography style={webStyle.meetingText}>
                    Renotary Meeting
                  </Typography>
                </Box>
                <IconButton style={{ padding: "8px" }}>
                  <BiMessageSquareDetail
                    style={{
                      color: "#3D4754",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </IconButton>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box display={"flex"} style={{ columnGap: "4px" }}>
                  <EventOutlined
                    style={{
                      width: "18px",
                      height: "18px",
                      color: "#475569",
                    }}
                  />
                  <Typography style={webStyle.meetingDetails}>
                    {this.findMeetDateTime()}
                  </Typography>
                </Box>
                <Box display={"flex"} style={{ columnGap: "2px" }}>
                  <MdOutlineLocationOn
                    style={{
                      color: "#475569",
                      width: "18px",
                      height: "18px",
                    }}
                  />
                  <Typography style={webStyle.meetingDetails}>
                    Zoom Call
                  </Typography>
                </Box>
              </Box>
            </Box>
            {this.isRequestStatusInProgress() && (
              <>
                <Box
                  display={"flex"}
                  style={{ columnGap: "8px" }}
                  py={"12px"}
                  borderTop={"1px solid #CBD5E1"}
                >
                  <IoCheckbox size={"20px"} style={{ color: "#011342" }} />
                  <Typography style={webStyle.meetingDetails}>
                    Notify 15 mins before meeting
                  </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"end"}>
                  <Button
                    variant="contained"
                    style={{
                      ...webStyle.tabInsiderButton,
                      textTransform: "none",
                      width: "324px",
                    }}
                    data-testID="joinMeetingButton"
                    onClick={() => this.setIsMeetingModalOpen(true)}
                  >
                    <Typography style={webStyle.tabInsiderButtonTypography}>
                      Join Meeting
                    </Typography>
                  </Button>
                </Box>
              </>
            )}
          </Box>
        )}
      </>
    );
  };

  renderDocusignTabPanel = () => {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        p={"24px"}
        style={{
          rowGap: "16px",
          borderRadius: "8px",
        }}
      >
        {this.isEndUser() && !this.state.hasDocusignStartedForEven1Document && (
          <>
            <Typography style={webStyle.tabTitle}>
              Document Signing Request
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              style={{ rowGap: "14px" }}
              alignItems={"center"}
            >
              <Box p={"16px"}>
                <img src={verified} />
              </Box>
              <Typography style={webStyle.tabTitle}>
                No Document Signing request
              </Typography>
            </Box>
          </>
        )}
        {this.areDocumentDetailsShown() && (
          <>
            <Box>
              <Typography style={webStyle.docuSignHeading}>
                Document Signing Status
              </Typography>
              <Typography style={webStyle.docuSignSubHeading}>
                The status of documents sent for sign
              </Typography>
            </Box>
            {this.state.documentDetails.map(
              (document, documentIndex) =>
                this.isThisDocumentShown(document.is_docusign_start) && (
                  <Box
                    p={"12px"}
                    display={"flex"}
                    flexDirection={"column"}
                    style={{
                      borderRadius: "16px",
                      gap: "12px",
                      backgroundColor: "#F5F9FF",
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Typography style={webStyle.docIndexTypography}>
                        Document #{documentIndex + 1}
                      </Typography>
                      {this.isStartOrSignNowButtonShown(document) && (
                        <Button
                          style={{
                            borderRadius: "8px",
                            padding: "10px 16px",
                            width: "120px",
                            height: "44px",
                            backgroundColor: "#012275",
                          }}
                          data-testID="startOrSignNowButton"
                          onClick={() =>
                            this.handleStartOrSignNowButtonClick(document)
                          }
                        >
                          <Typography
                            style={{
                              ...webStyle.buttonTypography,
                              color: "#FFF",
                            }}
                          >
                            {this.isStartOrSignNow(document.is_docusign_start)}
                          </Typography>
                        </Button>
                      )}
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      p={"8px"}
                      style={{
                        backgroundColor: "#FFF",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
                      }}
                    >
                      <Box display={"flex"} style={{ gap: "8px" }}>
                        <Box style={webStyle.documentIconBox}>
                          <DescriptionOutlined style={webStyle.documentIcon} />
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection="column"
                          style={{ gap: "4px" }}
                        >
                          <Typography style={webStyle.documentName}>
                            {document.file_name}
                          </Typography>
                          <Typography style={webStyle.documentSignatories}>
                            {this.findSignatories(document.signatory.length)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <IconButton style={{ padding: "10px" }}>
                          <VisibilityOutlined
                            style={{
                              color: "#012275",
                              height: "24px",
                              width: "24px",
                            }}
                          />
                        </IconButton>
                        <IconButton style={{ padding: "10px" }}>
                          <MdOutlineFileDownload
                            style={{
                              color: "#012275",
                              height: "24px",
                              width: "24px",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      p={"12px 16px"}
                      borderRadius={"8px"}
                      display={"flex"}
                      bgcolor={"#FFF"}
                      flexDirection={"column"}
                      style={{ gap: "12px" }}
                    >
                      <Typography style={webStyle.signatoriesNotaryText}>
                        Signatories
                      </Typography>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        style={{ gap: "12px" }}
                      >
                        {document.signatory.map(
                          (signatory) =>
                            signatory.is_signatory && (
                              <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Box display={"flex"} style={{ gap: "8px" }}>
                                  <img
                                    style={{
                                      borderRadius: "50%",
                                      backgroundColor: "grey",
                                    }}
                                    src={localProfile}
                                    height={"32px"}
                                    width={"32px"}
                                  />
                                  <Box>
                                    <Typography style={webStyle.signatoryName}>
                                      {signatory.name}
                                    </Typography>
                                    <Typography style={webStyle.signatoryEmail}>
                                      {signatory.email}
                                    </Typography>
                                  </Box>
                                </Box>
                                <SignStatusBox>
                                  <Typography
                                    className={`${this.isSignedOrPending(
                                      signatory.signed
                                    )} statusBox`}
                                  >
                                    {this.isSignedOrPending(signatory.signed)}
                                  </Typography>
                                </SignStatusBox>
                              </Box>
                            )
                        )}
                      </Box>
                    </Box>
                    <Box
                      p={"12px 16px"}
                      borderRadius={"8px"}
                      display={"flex"}
                      bgcolor={"#FFF"}
                      flexDirection={"column"}
                      style={{ gap: "12px" }}
                    >
                      <Typography style={webStyle.signatoriesNotaryText}>
                        Notary
                      </Typography>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"} style={{ gap: "8px" }}>
                          <img
                            style={{
                              borderRadius: "50%",
                              backgroundColor: "grey",
                            }}
                            src={localProfile}
                            height={"32px"}
                            width={"32px"}
                          />
                          <Box>
                            <Typography style={webStyle.signatoryName}>
                              {document.notary[0].name}
                            </Typography>
                            <Typography style={webStyle.signatoryEmail}>
                              {document.notary[0].email}
                            </Typography>
                          </Box>
                        </Box>
                        <SignStatusBox>
                          <Typography
                            className={`${
                              document.notary[0].signed ? "signed" : "pending"
                            } statusBox`}
                          >
                            {document.notary[0].signed ? "SIGNED" : "PENDING"}
                          </Typography>
                        </SignStatusBox>
                      </Box>
                    </Box>
                  </Box>
                )
            )}
          </>
        )}
      </Box>
    );
  };

  renderDescriptionBox = () => {
    return (
      <>
        {this.state.description !== "" && (
          <Box
            p={"24px"}
            display={"flex"}
            flexDirection={"column"}
            style={{
              borderRadius: "8px",
              backgroundColor: "#FFF",
              rowGap: "16px",
            }}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontWeight: 600,
                fontSize: "16px",
                color: "#012275",
                lineHeight: "22px",
              }}
            >
              Description
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#011342",
              }}
            >
              {this.state.description}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  renderOrderReceiptBox = () => {
    return (
      <>
        {this.isEndUser() && (
          <Box
            p={"24px"}
            display={"flex"}
            flexDirection={"column"}
            style={{
              borderRadius: "8px",
              backgroundColor: "#FFF",
              rowGap: "16px",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"end"}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "26px",
                  color: "#012275",
                }}
                className="orderReceipt"
              >
                Order Receipt
                <span style={{ color: "#CCD3E3" }}>(Coming Soon)</span>
              </Typography>
              <Button
                endIcon={<MdOutlineFileDownload color="#64748B" />}
                variant="contained"
                style={{
                  color: "#E2E8F0",
                  borderRadius: "4px",
                  padding: "6px 10px",
                  textTransform: "none",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#64748B",
                  }}
                >
                  Download
                </Typography>
              </Button>
            </Box>
            <Typography
              style={{
                fontFamily: "Inter",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#011342",
              }}
            >
              Your order receipt will be available for download once the order
              has been completed successfully.
            </Typography>
          </Box>
        )}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const TabPanel = (props: TabPanelProps) => {
      const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {children}
        </div>
      );
    };

    return (
      <>
        <Loader loading={this.state.loader} />
        <CancelNotaryRequestModal
          data-testID="cancelNotaryModal"
          text={this.state.cancelReqModalTitle}
          subText={this.findSubtext()}
          cancelImage={cancelImage}
          cancelReqModal={this.state.cancelReqModal}
          handleYesButtonClick={this.yesButtonClick}
          handleNoButtonClick={this.noButtonClick}
          yesBtnText="Yes"
        />
        <RequestModal
          navigation={undefined}
          id={""}
          isOpen={this.state.modalOpen}
          closeModal={this.closeBookNotaryRequestModal}
          data-testID="modalOpen"
          allRequestAPI={() => {}}
          serviceData={this.state.serviceData}
          cancelReqModal={this.state.cancelBookNowReqModal}
          yesButtonClick={this.bookNowYesButtonClick}
          noButtonClick={this.bookNowNoButtonClick}
          setLoader={this.setLoader}
          setModal={this.setBookNowModal}
          editRequest={undefined}
          isNewRequestOrEditRequestOrInviteClient={"new"}
        />
        <MakeQuoteModal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={this.state.isMakeQuoteModalOpen}
        >
          <Paper elevation={0} className="makeQuoteModalPaper">
            <Box
              width={"568px"}
              alignItems={"center"}
              display={"flex"}
              flexDirection={"column"}
              style={{ rowGap: "32px" }}
            >
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pb={"8px"}
                borderBottom={"1px solid #CBD5E1"}
              >
                <Typography style={webStyle.quotationHeading}>
                  Quotation
                </Typography>
                <Box p={"10px"}>
                  <IconButton
                    style={{ padding: 0 }}
                    data-testID="closeMakeQuoteModal"
                    onClick={this.closeMakeQuoteModal}
                  >
                    <Close
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#011342",
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box
                display={"flex"}
                className="innerBox"
                width={"536px"}
                flexDirection={"column"}
                maxHeight={"calc(100vh - 300px)"}
                style={{
                  rowGap: "24px",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <Box
                  display={"flex"}
                  width={"100%"}
                  flexDirection={"column"}
                  style={{ gap: "4px" }}
                >
                  <Typography style={webStyle.fieldTitle}>
                    Choose time <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box display={"flex"} style={{ gap: "16px" }}>
                    <style>
                      {`
                        .custom-placeholder::placeholder {
                          color: #011342; 
                          opacity: 1;
                        }
                      `}
                    </style>
                    <Box>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          data-testID={"startTimePicker"}
                          margin="none"
                          inputVariant="outlined"
                          value={this.state.startTime}
                          onChange={this.handleStartTimeChange}
                          keyboardIcon={
                            <RiTimerLine style={{ color: "#011342" }} />
                          }
                          placeholder={"Start Time"}
                          InputLabelProps={{
                            shrink: false,
                          }}
                          InputProps={{
                            classes: {
                              input: "custom-placeholder",
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                    <Box>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardTimePicker
                          margin="none"
                          data-testID={"endTimePicker"}
                          id="time-picker"
                          inputVariant="outlined"
                          value={this.state.endTime}
                          onChange={this.handleEndTimeChange}
                          keyboardIcon={
                            <RiTimerLine style={{ color: "#011342" }} />
                          }
                          placeholder={"End Time"}
                          InputLabelProps={{
                            shrink: false,
                          }}
                          InputProps={{
                            classes: {
                              input: "custom-placeholder",
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </Box>
                  <Typography
                    data-testID="timeErrorText"
                    style={webStyle.errorText}
                  >
                    {this.state.timeErrorText}
                  </Typography>
                </Box>
                <Box
                  width={"100%"}
                  display={"flex"}
                  alignItems={"start"}
                  style={{ columnGap: "8px" }}
                >
                  {this.state.isVideoCallChecked ? (
                    <IconButton
                      onClick={this.toggleIsVideoCallChecked}
                      style={{ padding: 0 }}
                    >
                      <IoCheckbox size={"20px"} style={{ color: "#011342" }} />
                    </IconButton>
                  ) : (
                    <IconButton
                      data-testID={"videoCallButton"}
                      style={{ padding: 0 }}
                      onClick={this.toggleIsVideoCallChecked}
                    >
                      <RxBox
                        data-testID={"videoCallUnchecked"}
                        size={"20px"}
                        style={{ color: this.findVideoCallCheckboxColor() }}
                      />
                    </IconButton>
                  )}
                  <Box
                    flexDirection={"column"}
                    display={"flex"}
                    style={{ gap: "4px" }}
                  >
                    <Typography
                      style={{
                        ...webStyle.checkBoxTitle,
                        color: this.findVideoCallColor(),
                      }}
                    >
                      Video call required{" "}
                      {this.isVideoCallAsteriskShown() && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </Typography>
                    <Typography
                      style={{
                        ...webStyle.checkBoxSubtitle,
                        color: this.findVideoCallColor(),
                      }}
                    >
                      I understand that I am requesting remote digital
                      notarisation. I will need to have audio/video capability
                      for the actual signing.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={"100%"}
                  flexDirection={"column"}
                  display={"flex"}
                  style={{ gap: "12px" }}
                >
                  <Box
                    flexDirection={"column"}
                    width={"100%"}
                    display={"flex"}
                    style={{ gap: "4px" }}
                  >
                    <Typography style={webStyle.fieldTitle}>
                      Your Fees <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <Box
                      display={"flex"}
                      width={"100%"}
                      style={{ gap: "12px" }}
                    >
                      <Box width={"80px"}>
                        <CustomAutocomplete
                          options={["£"]}
                          value={"£"}
                          onChange={undefined}
                          label={""}
                          isAsteriskShown={false}
                          disabled={true}
                          border="1px solid #CBD5E1"
                          labelColor="#011342"
                        />
                      </Box>
                      <CustomTextField
                        placeholder={"Enter your fees"}
                        onChange={this.handleFeesChange}
                        data-testID={"feesField"}
                        value={this.state.fees}
                        borderColor={"#CBD5E1"}
                        isAsteriskShown={true}
                        type={"tel"}
                        endAdornment={
                          <InputAdornment position="end">
                            <MdOutlinePayments
                              style={{
                                color: "#011342",
                                width: "24px",
                                height: "24px",
                              }}
                            />
                          </InputAdornment>
                        }
                      />
                    </Box>
                    <Typography style={webStyle.errorText}>
                      {this.state.feesErrorText}
                    </Typography>
                  </Box>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignItems={"start"}
                    style={{ columnGap: "8px" }}
                  >
                    {this.state.isPlatformFeesChecked ? (
                      <IconButton
                        onClick={this.toggleIsPlatformFeesChecked}
                        style={{ padding: 0 }}
                      >
                        <IoCheckbox
                          size={"20px"}
                          style={{ color: "#011342" }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        style={{ padding: 0 }}
                        data-testID={"platformFeesButton"}
                        onClick={this.toggleIsPlatformFeesChecked}
                      >
                        <RxBox
                          data-testID={"platformFeesUnchecked"}
                          size={"20px"}
                          style={{ color: "#64748B" }}
                        />
                      </IconButton>
                    )}
                    <Box
                      flexDirection={"column"}
                      display={"flex"}
                      style={{ gap: "4px" }}
                    >
                      <Typography style={webStyle.checkBoxTitle}>
                        Including platform fees
                      </Typography>
                      <Typography style={webStyle.checkBoxSubtitle}>
                        Tick the check box if your fees includes platform fees
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  style={{ gap: "4px" }}
                  width={"100%"}
                >
                  <Typography style={webStyle.fieldTitle}>
                    Message(optional)
                  </Typography>
                  <Box className="fieldBox" position={"relative"}>
                    <TextField
                      className="textField"
                      data-testID="messageField"
                      fullWidth
                      multiline
                      rows={4}
                      value={this.state.message}
                      onChange={this.handleMessageChange}
                    />
                    <FormHelperText
                      style={{ position: "absolute", bottom: "0", right: "10" }}
                    >
                      {500 - this.state.message.length} characters left
                    </FormHelperText>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              width={"398px"}
              height={"44px"}
              display={"flex"}
              style={{ columnGap: "12px" }}
            >
              <Button
                variant="outlined"
                fullWidth
                data-testID={"makeQuoteDeclineButton"}
                style={{
                  borderColor: "#FF0000",
                  color: "#FF0000",
                }}
                onClick={this.closeMakeQuoteModal}
              >
                <Typography style={webStyle.buttonTypography}>
                  Decline
                </Typography>
              </Button>
              <Button
                variant="contained"
                data-testID="submitQuoteButton"
                fullWidth
                style={{
                  backgroundColor: this.findSubmitButtonBackgroundColor(),
                  color: "#FFF",
                }}
                disabled={this.state.isSubmitQuoteButtonDisabled}
                onClick={this.handleSubmitQuote}
              >
                <Typography style={webStyle.buttonTypography}>
                  Submit
                </Typography>
              </Button>
            </Box>
          </Paper>
        </MakeQuoteModal>
        <SuccessFailureModal
          data-testID={"successFailureModal"}
          image={this.state.successFailModalImage}
          isOpen={this.state.isSuccessFailModalOpen}
          text={this.state.successFailModalText}
          textColor={this.state.successFailModalTextColor}
          subText={this.state.successFailModalSubText}
          subText2={this.state.successFailModalSubText2}
          handleButtonClick={this.handleSuccessFailureModalButtonClick}
          buttonText={this.state.successFailModalButtonText}
          modalWidth="472px"
        />
        <PreviewQuoteModal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          data-testID="previewQuoteModal"
          open={this.state.isPreviewQuoteModalOpen}
        >
          <Paper elevation={0} className="previewQuoteModalPaper">
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              pb={"8px"}
              borderBottom={"1px solid #CBD5E1"}
            >
              <Typography style={webStyle.quoteHeading}>
                Quote #{this.state.quotePreviewIndex}
              </Typography>
              <Box p={"10px"}>
                <IconButton
                  style={{ padding: 0 }}
                  data-testID="closePreviewQuoteModal"
                  onClick={this.closePreviewQuote}
                >
                  <Close
                    style={{ width: "24px", height: "24px", color: "#011342" }}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              maxHeight={"calc(100vh - 300px)"}
              flexDirection={"column"}
              className="innerBox"
              style={{
                overflowY: "auto",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Box
                    display={"flex"}
                    width={"90%"}
                    flexDirection={"column"}
                    style={{ gap: "4px" }}
                  >
                    <Typography style={webStyle.quoteDetailTypography1}>
                      Date:
                    </Typography>
                    <Typography style={webStyle.quoteDetailTypography2}>
                      {this.formNotaryRequestDate()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Box
                    display={"flex"}
                    width={"90%"}
                    flexDirection={"column"}
                    style={{ gap: "4px" }}
                  >
                    <Typography style={webStyle.quoteDetailTypography1}>
                      Time:
                    </Typography>
                    <Typography style={webStyle.quoteDetailTypography2}>
                      {this.getQuoteTime()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    style={{ gap: "4px" }}
                  >
                    <Typography style={webStyle.quoteDetailTypography1}>
                      Fees:
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Typography style={webStyle.quoteDetailTypography1}>
                        Notary Fees:
                      </Typography>
                      <Typography style={webStyle.quoteDetailTypography1}>
                        £{this.state.quoteNotaryFees}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Typography style={webStyle.quoteDetailTypography1}>
                        Platform Fees:
                      </Typography>
                      <Typography style={webStyle.quoteDetailTypography1}>
                        +£{this.state.quotePlatformFees}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Typography style={webStyle.quoteDetailTypography2}>
                        Total:
                      </Typography>
                      <Typography style={webStyle.quoteDetailTypography2}>
                        £
                        {(
                          parseFloat(this.state.quotePlatformFees) +
                          parseFloat(this.state.quoteNotaryFees)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box
                display={"flex"}
                flexDirection={"column"}
                style={{ gap: "4px" }}
              >
                <Typography style={webStyle.quoteDetailTypography1}>
                  Message
                </Typography>
                <Box>
                  <style>{`
                    
                    `}</style>
                  <Box
                    display={"flex"}
                    border={"1px solid #CBD5E1"}
                    p={"10px 12px"}
                    height={"92px"}
                    className="innerBox"
                    borderRadius={"4px"}
                    style={{
                      overflowY: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                  >
                    <Typography
                      style={webStyle.messageTypography}
                      dangerouslySetInnerHTML={{
                        __html: this.state.quoteMessage,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Box width={"552px"} display={"flex"} style={{ gap: "12px" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  style={{
                    ...webStyle.buttonStyle,
                    borderColor: "#5D5D5D",
                    color: "#000A34",
                    textTransform: "none",
                  }}
                  onClick={this.closePreviewQuote}
                >
                  <Typography style={webStyle.buttonTypography}>
                    Cancel
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    ...webStyle.buttonStyle,
                    backgroundColor: "#012275",
                    color: "#FFF",
                    textTransform: "none",
                  }}
                  data-testID="payNowButton"
                  onClick={this.redirectToPaymentOptions}
                >
                  <Typography style={webStyle.buttonTypography}>
                    Pay Now
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Paper>
        </PreviewQuoteModal>
        <ZoomMeetingModal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          data-testID="zoomMeetingModal"
          open={this.state.isZoomModalOpen}
        >
          <Paper elevation={0} className="zoomMeetingModalPaper">
            <Box
              display={"flex"}
              mt={"8px"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box height={"62px"} display={"flex"} justifyContent={"center"}>
                <img src={logoBlueImg} width={"auto"} height={"42px"} />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                style={{ rowGap: "8px" }}
              >
                <Typography align="center" style={webStyle.zoomModalText}>
                  Click <b>Allow</b> on the dialog shown by your browser
                  <br />
                  If you don't see a dialog, click <b>Launch Meeting</b> below
                </Typography>
                <Typography align="center" style={webStyle.zoomModalText2}>
                  By joining a meeting, you agree to our{" "}
                  <span style={{ color: "#1A73E8" }}>Terms of Service</span> and{" "}
                  <span style={{ color: "#1A73E8" }}>Privacy Statement</span>
                </Typography>
              </Box>
            </Box>
            <Box
              display={"flex"}
              mb={"16px"}
              justifyContent={"center"}
              style={{ columnGap: "12px" }}
            >
              <Button
                variant="outlined"
                data-testID="zoomModalCancelButton"
                style={{
                  ...webStyle.tabInsiderButton,
                  backgroundColor: "#FFF",
                  borderColor: "#5D5D5D",
                  color: "#5D5D5D",
                  textTransform: "none",
                  width: "193px",
                }}
                onClick={() => this.setIsMeetingModalOpen(false)}
              >
                <Typography style={webStyle.buttonTypography}>
                  Cancel
                </Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  ...webStyle.tabInsiderButton,
                  textTransform: "none",
                  width: "193px",
                }}
                data-test-id="launchMeetingButton"
                onClick={this.navigateToMeeting}
              >
                <Typography style={webStyle.tabInsiderButtonTypography}>
                  Launch Meeting
                </Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  ...webStyle.tabInsiderButton,
                  textTransform: "none",
                  width: "324px",
                }}
                data-testID="videoSdkButton"
                onClick={this.navigateToVideoMeeting}
              >
                <Typography style={webStyle.tabInsiderButtonTypography}>
                  Video SDK
                </Typography>
              </Button>
            </Box>
          </Paper>
        </ZoomMeetingModal>
        <Box display={"flex"} className="test1">
          <DesktopDrawerBox>
            <NavigationMenu navigation={this.props.navigation} id={""} />
          </DesktopDrawerBox>
          <MobileTabletDrawerBox>
            {this.renderMobileNavigationMenu()}
          </MobileTabletDrawerBox>
          <MainBox
            height={"100vh"}
            overflow={"auto"}
            width={this.findMainBoxWidth()}
            style={{ backgroundColor: "#F9F9F9" }}
          >
            {this.Header()}
            <MainContentBox mt={"32px"} mx={"27px"}>
              <LeftSideBox
                display={"flex"}
                flexDirection={"column"}
                style={{ rowGap: "24px", alignSelf: "flex-start" }}
                width={"60%"}
                mr={"27px"}
              >
                <Box
                  p={"24px"}
                  display={"flex"}
                  flexDirection={"column"}
                  style={{
                    borderRadius: "8px",
                    backgroundColor: "#FFF",
                    rowGap: "24px",
                  }}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box
                      display={"flex"}
                      style={{ columnGap: "12px" }}
                      alignItems={"center"}
                    >
                      <img
                        style={{ borderRadius: "50%", backgroundColor: "red" }}
                        src={service}
                        width="32px"
                        height="32px"
                        alt="profile_pic"
                      />
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontWeight: 600,
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: "#0131A8",
                        }}
                      >
                        Request Details
                      </Typography>
                    </Box>
                    {this.renderCancelRequestButton()}
                  </Box>
                  <Grid container style={{ rowGap: "24px" }}>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Box pr={"16px"}>
                        <Typography style={webStyle.detailKey}>
                          Type of notary service:
                        </Typography>
                        <Typography style={webStyle.detailValue}>
                          {this.state.typeOfNotaryService}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Box pr={"16px"}>
                        <Typography style={webStyle.detailKey}>
                          Method of notarisations:
                        </Typography>
                        <Typography style={webStyle.detailValue}>
                          {this.state.notarisationMethod}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Box pr={"16px"}>
                        <Typography style={webStyle.detailKey}>
                          Number of Documents:
                        </Typography>
                        <Typography style={webStyle.detailValue}>
                          {this.state.documents.length}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Box pr={"16px"}>
                        <Typography style={webStyle.detailKey}>
                          Notary Date:
                        </Typography>
                        <Typography
                          style={{
                            ...webStyle.detailValue,
                            color: this.findDateColor(),
                          }}
                        >
                          {this.findNotaryDateSession()}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Box pr={"16px"}>
                        <Typography style={webStyle.detailKey}>
                          Jurisdiction:
                        </Typography>
                        <Typography style={webStyle.detailValue}>
                          {this.state.jurisdiction}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                      <Box pr={"16px"}>
                        <Typography style={webStyle.detailKey}>
                          Number of signatory:
                        </Typography>
                        <Typography style={webStyle.detailValue}>
                          {this.state.numberOfSignatory}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {this.renderMakeQuoteAndDeclineButton()}
                {this.areTabsShown() && (
                  <Box style={{ borderRadius: "8px", backgroundColor: "#FFF" }}>
                    <AppBar
                      className="tabChange"
                      position="static"
                      style={{ backgroundColor: "#F9F9F9", boxShadow: "none" }}
                    >
                      <Tabs
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#0131A8",
                          },
                        }}
                        variant="scrollable"
                        scrollButtons="off"
                        data-testID={"tabs"}
                        value={this.state.tabIndex}
                        onChange={this.handleTabChange}
                        aria-label="simple tabs example"
                      >
                        <Tab
                          label={this.toShowMeetingTab() ? "Meeting" : "Quotes"}
                          {...this.a11yProps(0)}
                          style={{
                            ...webStyle.tabHeadings,
                            textTransform: "none",
                            color: this.findTabColor(0),
                          }}
                        />
                        <Tab
                          label="Document Signing"
                          disabled={
                            this.state.areTabsDisabled ||
                            this.state.notarisationMethod.includes("RIN")
                          }
                          {...this.a11yProps(1)}
                          style={{
                            ...webStyle.tabHeadings,
                            textTransform: "none",
                            color: this.findTabColor(1),
                          }}
                        />
                        <Tab
                          label="Tracking Details"
                          disabled={this.state.areTabsDisabled}
                          {...this.a11yProps(2)}
                          style={{
                            ...webStyle.tabHeadings,
                            textTransform: "none",
                            color: this.findTabColor(2),
                          }}
                        />
                        <Tab
                          label="KYC Verification"
                          disabled={this.state.areTabsDisabled}
                          {...this.a11yProps(3)}
                          style={{
                            ...webStyle.tabHeadings,
                            textTransform: "none",
                            color: this.findTabColor(3),
                          }}
                        />
                      </Tabs>
                    </AppBar>
                    <Box style={{ backgroundColor: "#FFF" }}>
                      <TabPanel
                        data-testID="quoteOrMeetingTab"
                        value={this.state.tabIndex}
                        index={0}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          style={{ gap: "16px" }}
                        >
                          {this.renderNullQuotesMessage()}
                          {this.renderQuotesForEndUser()}
                          {this.renderQuotesForNotaryUser()}
                          {this.renderMeetingTab()}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        data-testID="docusignTab"
                        value={this.state.tabIndex}
                        index={1}
                      >
                        {this.renderDocusignTabPanel()}
                      </TabPanel>
                      <TabPanel
                        data-testID="trackingDetailsTab"
                        value={this.state.tabIndex}
                        index={2}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          p={"24px"}
                          style={{ rowGap: "16px" }}
                        >
                          {this.state.tabIndex === 2 && (
                            <TrackingDetails
                              navigation={this.props.navigation}
                              id={""}
                              email={this.state.email}
                              data-testID="trackingDetails"
                            />
                          )}
                        </Box>
                      </TabPanel>
                      <TabPanel
                        data-testID="KYCVerificationTab"
                        value={this.state.tabIndex}
                        index={3}
                      >
                        {this.isEndUser() && this.state.endUserDocStatus && (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            p={"24px"}
                            style={{ rowGap: "16px" }}
                          >
                            <Typography style={webStyle.tabTitle}>
                              KYC Request
                            </Typography>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              style={{ rowGap: "14px" }}
                              alignItems={"center"}
                            >
                              <Box p={"16px"}>
                                <img src={verified} />
                              </Box>
                              <Typography style={webStyle.tabTitle}>
                                No KYC verification request
                              </Typography>
                              <Box maxWidth={"329px"}>
                                <Typography
                                  style={webStyle.kycInfoText}
                                  align="center"
                                >
                                  You don't have any kyc verification request
                                  sent by your notary
                                </Typography>
                              </Box>
                            </Box>
                            
                          </Box>
                        )}
                        {this.isNotaryUser() && this.state.endUserDocStatus && (
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            p={"24px"}
                            style={{ rowGap: "16px" }}
                          >
                            <Typography style={webStyle.tabTitle}>
                              KYC Request
                            </Typography>
                            <Typography style={webStyle.tabDetails}>
                              The KYC verification helps to confirm the identity
                              and other demographics before proceeding with
                              notary to eliminate chances of fraud
                            </Typography>

                            
                            
                          </Box>
                        )}

                      {this.state.tabIndex === 3 && <KnowYourCustomerKycVerification navigation={this.props.navigation} orderID={this.state.notaryRequestId} initialEndUserDocStatus={this.docStatusResultForEndUser} isEndUser={this.isEndUser()} isNotaryUser={this.isNotaryUser()} />}
                      </TabPanel>
                    </Box>
                  </Box>
                )}
              </LeftSideBox>
              <RightSideBox
                width={"40%"}
                display={"flex"}
                flexDirection={"column"}
                style={{ alignSelf: "flex-start", rowGap: "24px" }}
              >
                {this.renderDescriptionBox()}
                <Box
                  p={"24px"}
                  display={"flex"}
                  flexDirection={"column"}
                  style={{
                    rowGap: "16px",
                    borderRadius: "8px",
                    backgroundColor: "#FFF",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "#012275",
                      lineHeight: "22px",
                    }}
                  >
                    Document(s)
                  </Typography>
                  {this.state.documents.map((doc: FileDocumentsEntity) => (
                    <Box
                      display={"flex"}
                      style={{
                        columnGap: "8px",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
                        borderRadius: "8px",
                      }}
                      p={"8px"}
                    >
                      <Box style={webStyle.documentIconBox}>
                        <DescriptionOutlined style={webStyle.documentIcon} />
                      </Box>
                      <Box>
                        <Typography style={webStyle.documentName}>
                          {doc.doc_name}
                        </Typography>
                        <Typography style={webStyle.documentSignatories}>
                          {this.findSignatoriesText(doc)}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                {this.renderOrderReceiptBox()}
              </RightSideBox>
            </MainContentBox>
          </MainBox>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PreviewQuoteModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  "& .previewQuoteModalPaper": {
    borderRadius: "8px",
    width: "607px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "24px 16px",
    boxSizing: "border-box",
    gap: "32px",
  },
  "& .messageBox": {
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  "& .innerBox": {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

const MakeQuoteModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .makeQuoteModalPaper": {
    borderRadius: "8px",
    width: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "24px 16px 32px",
    boxSizing: "border-box",
    gap: "32px",
    "& .MuiButton-root": {
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
      height: "auto",
    },
  },
  "& .fieldBox": {
    "& .MuiInput-underline": {
      "&:before": { borderBottom: "0px" },
      "&:after": { borderBottom: "0px" },
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  "& .textField": {
    "& .MuiInputBase-input": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      color: "#011342",
      fontFamily: "Inter",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    border: "1px solid #011342",
    borderRadius: "8px",
    padding: "8px 8px",
    boxSizing: "border-box",
    underline: "none",
  },
  "& .innerBox": {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
});

const ZoomMeetingModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  "& .zoomMeetingModalPaper": {
    borderRadius: "8px",
    width: "100%",
    maxWidth: "607px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "24px 16px",
    boxSizing: "border-box",
    gap: "32px",
  },
});

const StyledIconButton = styled(IconButton)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const MainContentBox = styled(Box)({
  "@media (min-width: 1025px)": {
    display: "flex",
  },
});

const LeftSideBox = styled(Box)({
  "@media (min-width: 1025px)": {
    width: "60%",
  },
  width: "100%",
});

const RightSideBox = styled(Box)({
  "@media (min-width: 1025px)": {
    width: "40%",
  },
  "@media (max-width: 1024px)": {
    marginTop: "24px",
  },
  width: "100%",
});

const MainBox = styled(Box)({
  "@media (min-width: 1025px)": {
    width: "calc(100vw - 200px)",
  },
});

const DesktopDrawerBox = styled(Box)({
  "@media (max-width: 1024px)": {
    display: "none",
  },
  display: "flex",
});

const MobileTabletDrawerBox = styled(Box)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const StatusBox = styled(Box)({
  "& .completed": {
    background: "#D1FAE5",
    color: "#059669",
  },
  "& .cancelled": {
    background: "#FF000038",
    color: "#FF0000",
  },
  "& .pending": {
    background: "#F0E5FF",
    color: "#6200EA",
  },
  "& .inprogress": {
    background: "#FEF3C7",
    color: "#D97706",
  },
  "& .statusBox": {
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 12px",
  },
});

const SignStatusBox = styled(Box)({
  "& .statusBox": {
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 8px",
    fontFamily: "INTER",
    fontSize: "12px",
    fontWeight: 700,
  },
  "& .SIGNED": {
    background: "#D1FAE5",
    color: "#059669",
  },
  "& .PENDING": {
    background: "#FEF3C7",
    color: "#F59E0B",
  },
});

const webStyle = {
  signatoryName: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    color: "#000",
  },
  signatoryEmail: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    color: "#000",
  },
  signatoriesNotaryText: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    color: "#334155",
  },
  docIndexTypography: {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    color: "#334155",
  },
  docuSignHeading: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    color: "#011342",
  },
  docuSignSubHeading: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#334155",
  },
  documentIconBox: {
    width: "42px",
    height: "43px",
    borderRadius: "4px",
    backgroundColor: "#0131A8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  documentIcon: {
    color: "#FFF",
    height: "24px",
    width: "24px",
  },
  docusignInfoText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#334155",
  },
  kycInfoText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 300,
    color: "#64748B",
  },
  zoomModalText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    color: "#1E293B",
  },
  zoomModalText2: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#1E293B",
  },
  tabTitle: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    color: "#011342",
  },
  tabDetails: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    color: "#011342",
    maxWidth: "800px"
    
  },
  meetingDetails: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#475569",
  },
  meetingText: {
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 600,
    color: "#011342",
  },
  buttonStyle: {
    flex: 1,
    borderRadius: "8px",
    padding: "10px 16px",
    height: "44px",
  },
  quoteHeading: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#011342",
  },
  tabInsiderButtonTypography: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#FFF",
  },
  tabInsiderButton: {
    backgroundColor: "#012275",
    borderRadius: "4px",
    padding: 0,
    height: "44px",
  },
  quoteDetailTypography1: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "21px",
    color: "8B91A9",
  },
  quoteDetailTypography2: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#011342",
  },
  messageTypography: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#011342",
  },
  quoteTitle: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: "#011342",
  },
  errorText: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#DC2626",
  },
  documentName: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#011342",
  },
  documentSignatories: {
    fontWeight: 500,
    fontFamily: "Inter",
    fontSize: "12px",
    lineHeight: "14.52px",
    color: "#011342",
  },
  tabHeadings: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Inter",
    minWidth: "auto",
    padding: "16px 18px",
  },
  buttonTypography: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  detailKey: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#5D5D5D",
  },
  detailValue: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#011342",
  },
  quotationHeading: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#011342",
  },
  checkBoxTitle: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#011342",
  },
  checkBoxSubtitle: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#011342",
  },
  fieldTitle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#011342",
  },
  timeHeading: {
    fontFamily: "INTER",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#334155",
  },
  startEndTime: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "64748B",
  },
  time: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "21px",
    color: "#011342",
  },
  timeOptionsText: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
  },
  timerIconStyle: {
    color: "#011342",
    height: "24px",
    width: "24px",
  },
  colon: {
    fontWeight: 500,
    fontFamily: "Inter",
    fontSize: "20px",
  },
};
// Customizable Area End
