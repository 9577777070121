import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
const navigation = require("react-navigation");

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";

// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface RowsData {
  "id": number,
  "account_id": number,
  "request_type": string | null,
  "payment_date": string | null,
  "payment_method": string | null,
  "transaction_number": string | null,
  "notary_request_id": number | null,
  "notary_id": string | null,
  "status": string | null,
  "amount": string,
  "created_at": string | null,
  "updated_at": string | null
}
export interface ApiCallInterface {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  type?: string;
}

export interface ValidResponseType {
  message: object;
  data: object;
  errors: string;
}

export interface ValidResponseType {
  message: object;
  data: object;
  errors: string;
  status: number;
}

interface S {
  // Customizable Area Start
  isSideBarOpen: boolean;
  transactionsList: any[]
  pages: string[]
  tabs: string[]
  tabeValue: number,
  filteredData: any[],
  orderId: string;
  sortData: string
  userRoleId: number
  token: string
  loader: boolean
  page: number;
  isLoading: boolean;
  hasMore: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TransactionHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tableContainerRef: React.RefObject<HTMLDivElement>;
  userProfileInfoCallId: string = "";

  fetchTransactionHistoryListId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isSideBarOpen: false,
      transactionsList: [],
      pages: ['ALL', 'PAID', 'FAILED'],
      tabs: ["All", "PAID", "FAILD"],
      tabeValue: 0,
      filteredData: [],
      orderId: '',
      sortData: 'decending',
      userRoleId: 0,
      token: '',
      loader: false,
      page: 1,
      isLoading: false,
      hasMore: true,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.tableContainerRef = React.createRef();
    this.scrollListener = this.scrollListener.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      setStorageData("token", token)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponse(webResponseJson)) {
        this.responseSuccessCallBack(webApiRequestCallId, webResponseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  }

  async componentDidMount() {
    super.componentDidMount();
    const token = await getStorageData("authToken");
    const filter = await getStorageData("filter");
    this.getToken();
    this.setToken(token);
    this.setState({ filteredData: this.state.transactionsList })
    this.getTransactionHistoryList();
    window.addEventListener('scroll', this.scrollListener);
    if (this.tableContainerRef.current) {
      this.tableContainerRef.current.addEventListener('scroll', this.scrollListener);
    }
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    if (this.tableContainerRef.current) {
      this.tableContainerRef.current.removeEventListener('scroll', this.scrollListener);
    }
  }

  scrollListener() {
    if (this.tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = this.tableContainerRef.current;
      if (scrollTop + clientHeight >= scrollHeight && this.state.hasMore && !this.state.isLoading) {
        this.setState(
          (prevState) => ({
            page: prevState.page + 1,
          }),
          () => {
            this.getTransactionHistoryList();
          }
        );
      }
    }
  }

  handleEventTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ tabeValue: newValue });
  };

  a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  handleUserDetails = (response: any) => {
    if (response.data) {
      const { attributes } = response.data;
      this.setState({ userRoleId: attributes.role_id });
    }else {
      const {error} = response;
      console.error(error);
    }
  }


  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ sortData: event.target.value as string });
  };

  handleOrderIdInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  
    this.setState({ orderId: event.target.value });
  };

  convertDate = (inputDate: string | Date) => {
    if (inputDate) {
      const date = new Date(inputDate);

      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();

      return `${day}/${month}/${year}`;
    }else {
      return ""
    }

  }

  goToUserProfile = () => {
    console.log('hhiii',this.props)
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfileBasicBlock");
    msgs.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msgs);
  }

  setToken = (token: string) => {
    if (token) {
      this.setState({ token });
      this.getUserDetails(token);
    }else {
      this.setState({ token: "" });
    }
  }

  getUserDetails = (token: string) => {
    if (token) {
      this.setState({ loader: true });
      const header = {
        "Content-Type": configJSON.ApiContentType,
        "token": token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.userProfileInfoCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUserProfileEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  apiCall = async (apiData: ApiCallInterface) => {
    let token = await getStorageData("token");
    const { contentType, method, endPoint, body } = apiData;
    const header = {
      "Content-Type": contentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getTabStatus = (value: number) => {
    if (value === 0) {
      return ""
    } else if (value === 1) {
      return "PAID"
    } else if (value === 2) {
      return "FAILED"
    }
  }

  getTransactionHistoryList = async () => {
    const { isLoading, hasMore, page } = this.state;

    if (isLoading && !hasMore) return;
    this.setState({ isLoading: true });
    const Status = this.getTabStatus(this.state.tabeValue);
    const orderId = this.state.orderId.length >0 ? Number(this.state.orderId) : ''
    this.fetchTransactionHistoryListId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.usersList}filter=${Status}&order_id=${orderId}&sort_by=${this.state.sortData}&per_page=${10}&page=${page}&sort_by_amount=${this.state.sortData}`
    });
  };

  responseSuccessCallBack = (apiRequestCallId: string, responseJson: any) => {
    this.setState({ loader: false });
    if (apiRequestCallId === this.fetchTransactionHistoryListId) {
      this.handleTransactionHistoryRes(responseJson);
    }
    if (apiRequestCallId === this.userProfileInfoCallId) {
      this.handleUserDetails(responseJson);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.tabeValue !== prevState.tabeValue || this.state.orderId !== prevState.orderId || this.state.sortData !== prevState.sortData) {

      this.setState({ transactionsList: [], hasMore: true, page: 1 }, ()=> this.getTransactionHistoryList());
    }
  }

  handleTransactionHistoryRes = (response: any) => {
    
    if (!response.error) {
      this.setState((prevState) => ({
        transactionsList: [...prevState.transactionsList, ...response.transaction_history],
        isLoading: false,
        hasMore: response.transaction_history.length === 10,
      }))
    } else {
      console.error("Error loading transactions:", response);
      this.setState({ isLoading: false, hasMore: false });
    }
  }
  // Customizable Area End
}
