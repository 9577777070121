import { BlockComponent } from "../../framework/src/BlockComponent";
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser';
import { removeStorageData, setStorageData } from "../../framework/src/Utilities";

interface Props { }

interface S { }

interface SS { }

const msalConfig = {
  auth: {
    clientId: '42b5b413-2926-4c01-96b3-683376a1c328',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

export class OutlookAuthProvider extends BlockComponent<Props, S, SS> {

  static getAccessToken = async (): Promise<string> => {
    const request = {
      scopes: ["User.Read", "Calendars.Read"],
    };

    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        const loginResponse = await msalInstance.loginPopup(request);
        setStorageData('ms_accessToken', loginResponse.accessToken);
        return loginResponse.accessToken;
      } else {
        const silentRequest = {
          account: accounts[0],
          scopes: request.scopes,
        };
        const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
        setStorageData('ms_accessToken', tokenResponse.accessToken);
        return tokenResponse.accessToken;
      }
    } catch (error) {
        console.error("Error acquiring token:", error);
        throw error;
      
    }
  };

  static signOut = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      
      if (accounts.length > 0) {
        await msalInstance.logoutPopup();
        removeStorageData("ms_accessToken");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error signing out:", error);
      return false;
    }
  };

  static fetchUserProfilePicture = async (accessToken: string) => {
    const photoEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await fetch(photoEndpoint, options);

      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = () => {
          const base64data = reader.result as string;
          resolve(base64data);
        };
        reader.onerror = (error) => {
          reject(error);
        };
      });
    } catch (error) {
      console.error(error);
      return "";
    }
  };
}

export default msalInstance;
