// Customizable Area Start
import React from "react";

import DocusignIntegrationController, {
  Props,
} from "./DocusignIntegrationController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import RequestModal from "./../../dashboard/src/BookNotaryRequest.web";
import Loader from "../../../components/src/Loader.web";
import { Box, IconButton, Typography, Button, styled } from "@material-ui/core";
import {
  Menu,
  Close,
  ArrowBackIosRounded,
  AddRounded,
} from "@material-ui/icons";
import { localProfile, bellIcon } from "./assets";

export default class DocusignIntegration extends DocusignIntegrationController {
  constructor(props: Props) {
    super(props);
  }

  Header = () => {
    return (
      <Box display={"flex"} alignItems={"center"} mt={"32px"}>
        <StyledIconButton data-testID="toggleButton" onClick={this.openSideBar}>
          {this.state.isSideBarOpen ? (
            <Close style={{ width: "50px", height: "56px" }} />
          ) : (
            <Menu style={{ width: "50px", height: "56px" }} />
          )}
        </StyledIconButton>
        <Box width={"calc(100vw - 74px)"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mx={"25px"}
          >
            <Box
              display={"flex"}
              height={"48px"}
              alignItems={"center"}
              style={{ columnGap: "8px" }}
            >
              <IconButton data-testID="backIconButton" onClick={this.goBack}>
                <ArrowBackIosRounded height={"24px"} width={"24px"} />
              </IconButton>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "32px",
                  letterSpacing: "-0.5%",
                }}
              >
                Document Signing
              </Typography>
            </Box>
            <Box display={"flex"} style={{ columnGap: "16px" }}>
              {this.isNotaryUser() && (
                <Button
                  style={{
                    backgroundColor: "#012275",
                    color: "#FFF",
                    height: "48px",
                    padding: "0 16px",
                  }}
                  startIcon={<AddRounded />}
                  data-testID="bookNowBtn"
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Invite Client
                  </Typography>
                </Button>
              )}
              {this.isEndUser() && (
                <Button
                  style={{
                    backgroundColor: "#012275",
                    color: "#FFF",
                    height: "48px",
                    padding: "0 16px",
                    textTransform: "none",
                  }}
                  onClick={() => this.setBookNowModal(true)}
                  startIcon={
                    <AddRounded style={{ height: "24px", width: "24px" }} />
                  }
                  data-testID="bookNowBtn"
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: 700,
                    }}
                  >
                    Book Now
                  </Typography>
                </Button>
              )}
              <Box
                borderRadius={"4px"}
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#FFF",
                }}
                width={"48px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"48px"}
              >
                <img src={bellIcon} />
              </Box>
              <Box
                borderRadius={"8px"}
                display={"flex"}
                height={"48px"}
                px={"8px"}
                alignItems={"center"}
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                  columnGap: "8px",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <img
                  style={{ borderRadius: "50%", backgroundColor: "grey" }}
                  src={
                    this.state.userProfilePic !== null
                      ? this.state.userProfilePic
                      : localProfile
                  }
                  width="32px"
                  height="32px"
                  alt="profile_pic"
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    fontFamily: "Inter",
                    fontSize: "14px",
                    lineHeight: "21px",
                  }}
                  data-testID="userName"
                  variant="body2"
                >
                  {this.state.userName}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  renderMobileNavigationMenu = () => {
    return (
      <>
        {this.state.isSideBarOpen && (
          <NavigationMenu navigation={this.props.navigation} id={""} />
        )}
      </>
    );
  };

  render() {
    return (
      <>
        <Loader loading={this.state.loader} />
        <RequestModal
          navigation={undefined}
          id={""}
          isOpen={this.state.modalOpen}
          closeModal={this.closeBookNotaryRequestModal}
          data-testID="modalOpen"
          allRequestAPI={() => {}}
          serviceData={this.state.serviceData}
          cancelReqModal={this.state.cancelBookNowReqModal}
          yesButtonClick={this.bookNowYesButtonClick}
          noButtonClick={this.bookNowNoButtonClick}
          setLoader={this.setLoader}
          setModal={this.setBookNowModal}
          editRequest={undefined}
          isNewRequestOrEditRequestOrInviteClient={"new"}
        />
        <Box display={"flex"} className="test1">
          <DesktopDrawerBox>
            <NavigationMenu navigation={this.props.navigation} id={""} />
          </DesktopDrawerBox>
          <MobileTabletDrawerBox>
            {this.renderMobileNavigationMenu()}
          </MobileTabletDrawerBox>
          <MainBox
            height={"100vh"}
            overflow={"auto"}
            width={this.findMainBoxWidth()}
            style={{ backgroundColor: "#F9F9F9" }}
          >
            {this.Header()}
            <MainContentBox mt={"32px"} mx={"27px"}>
              <style>
                {
                  `#docusign-iframe: {
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }`
                }
              </style>
              {this.state.signing_url !== "" && <iframe
                style={{ width: '100%', height: '100%', overflow: "hidden" }}
                id="docusign-iframe"
                frameBorder={"0"}
                src={this.state.signing_url}
              ></iframe>}
            </MainContentBox>
          </MainBox>
        </Box>
      </>
    );
  }
}

const MainBox = styled(Box)({
  "@media (min-width: 1025px)": {
    width: "calc(100vw - 200px)",
  },
});

const MainContentBox = styled(Box)({
  height: "calc(100vh - 144px)",
  "@media (min-width: 1025px)": {
    display: "flex",
  },
});

const DesktopDrawerBox = styled(Box)({
  "@media (max-width: 1024px)": {
    display: "none",
  },
  display: "flex",
});

const MobileTabletDrawerBox = styled(Box)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const StyledIconButton = styled(IconButton)({
  "@media (min-width: 1025px)": {
    display: "none",
  },
});

const StatusBox = styled(Box)({
  "& .inprogress": {
    background: "#FEF3C7",
    color: "#D97706",
  },
  "& .statusBox": {
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 12px",
  },
});
// Customizable Area End
// Customizable Area End
